import React from 'react'
import { LinearProgress, Box } from '@material-ui/core'

type Props = {
  enabled: boolean
}

export default function AbsolutePositionedLoader({ enabled = false }: Props) {
  if (!enabled) return null
  return (
    <Box position="absolute" bottom={0} width="100%">
      <LinearProgress />
    </Box>
  )
}

import { Buffer } from 'buffer'
import download from 'downloadjs'
import { get } from 'lodash'
import { getPdf } from '../lib/api'
import { trackDownloadPDF } from '../mixpanel'
import { PdfApiInput } from '../api-types'

// @ts-expect-error react scripts version 5 did not include buffer without some hacks
window.Buffer = Buffer

export default async function downloadPdf(
  formValues: PdfApiInput,
  setSubmitting: (isSubmitting: boolean) => void,
  reportNameTemplate: string
) {
  setSubmitting(true)
  try {
    const reportData = await getPdf(formValues)
    const bufferData = get(reportData, 'buffer.data', [])
    const buffer = Buffer.from(bufferData)
    const blob = new Blob([buffer], { type: 'application/pdf' })
    const reportName = `${formValues.street_address} - ${reportNameTemplate}`
    download(blob, reportName, 'application/pdf')
    trackDownloadPDF()
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error)
  }
  setSubmitting(false)
}

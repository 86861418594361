import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import { AddressSearch } from './AddressSearch'
import { CreateReportForm } from './CreateReportForm'
import useDefineInitialState, {
  SEARCH_BY_ADDRESS,
  SEARCH_BY_BUILDING_DETAILS,
} from './useDefineInitialState'

type Props = {
  email: string
}

export default function App({ email }: Props) {
  const { t } = useTranslation()
  const { buildingDetailsFromSearch, handleChange, expandedForm, updateBuildingDetails } =
    useDefineInitialState()
  return (
    <Box paddingTop="16px">
      <Accordion
        expanded={expandedForm === SEARCH_BY_ADDRESS}
        onChange={handleChange(SEARCH_BY_ADDRESS)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            {buildingDetailsFromSearch?.street_address || t('address.search')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddressSearch onSuccess={updateBuildingDetails} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedForm === SEARCH_BY_BUILDING_DETAILS}
        onChange={handleChange(SEARCH_BY_BUILDING_DETAILS)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{t('buildings.confirmDetails')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreateReportForm buildingDetailsFromSearch={buildingDetailsFromSearch} email={email} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

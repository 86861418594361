import { Box, Grid, Typography, Link } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import { useTranslation } from 'react-i18next'
import LanguageSwitch from './LanguageSwitch'
import constants from './constants-generated.json'

export default function BottomFooter() {
  return (
    <Box paddingTop={3} paddingBottom={1}>
      <Grid container>
        <Grid item xs={5}>
          <Link target="_blank" href={INSTRUCTIONS_URL} rel="noopener" underline="none">
            <Instructions />
          </Link>
        </Grid>
        <Grid item xs={2}>
          <LanguageSwitch />
        </Grid>
        <Grid item xs={5}>
          <Typography align="right" color="textSecondary" variant="body2">
            {constants.SUPPORT_EMAIL_ADDRESS}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const INSTRUCTIONS_URL = 'https://www.lampopumppulaskuri.fi/kayttoohjeetpro'

const Instructions = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex">
      <Typography align="center" color="textSecondary" variant="body2">
        {t('information.instructions')}
      </Typography>
      <Box paddingLeft={0.5}>
        <LaunchIcon color="disabled" fontSize="small" />
      </Box>
    </Box>
  )
}

import { MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { CustomSelectField } from '../fields'
import { HeatingDistributionMethod } from '../pdfs-graphql-generated'

type Props = {
  disabled: boolean
}

export default function HeatingDistriButionMethod({ disabled }: Props) {
  const { t } = useTranslation()

  return (
    <Field
      name="heating_distribution_method"
      label={t('fields.heatingDistributionMethod.fieldName')}
      component={CustomSelectField}
      disabled={disabled}
    >
      {(Object.values(HeatingDistributionMethod) as Array<HeatingDistributionMethod>).map(
        (method) => (
          <MenuItem key={method} value={method}>
            {t(`fields.heatingDistributionMethod.${method}`)}
          </MenuItem>
        )
      )}
    </Field>
  )
}

import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { isAuthorizedUser, getAccountByEmail } from './accounts'
import { setupMixpanelUser } from './mixpanel'
import constants from './constants-generated.json'

const useValidateUserAuthorization = (user: firebase.User | null) => {
  const [isAuthenticatedUser, setUser] = React.useState<boolean | undefined>(undefined)
  React.useEffect(() => {
    if (!user) {
      setUser(undefined)
    } else {
      const { email, emailVerified } = user
      if (email && emailVerified) {
        const authorizedUser = isAuthorizedUser(email)
        setUser(authorizedUser)
      }
    }
  }, [user])
  return isAuthenticatedUser
}

const firebaseConfig = {
  apiKey: 'AIzaSyCJhNPCT-vc6U_XwsY-Ud-5av2JIDFa-Kk',
  authDomain: 'lampopumppulaskuri.firebaseapp.com',
  projectId: 'lampopumppulaskuri',
  storageBucket: 'lampopumppulaskuri.appspot.com',
  messagingSenderId: '746718669845',
  appId: '1:746718669845:web:49bf1adb5834c9ba45321c',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const actionCodeSettings = {
  url: window.location.href,
  handleCodeInApp: true,
}

const signOut = () => firebase.auth().signOut()

const isExpired = (email: string | null, timestampString: string | null) => {
  if (!email || !timestampString) return true
  const timeNow = new Date()
  const signedIdDate = new Date(timestampString)
  const difference = timeNow.getTime() - signedIdDate.getTime()
  const expiresInDays = 90
  return Math.floor(difference / 1000 / 60 / 60 / 24) > expiresInDays
}

const useAuthStateListener = () => {
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null>(null)
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        const email = window.localStorage.getItem('emailForSignIn')
        const timestampString = window.localStorage.getItem('emailForSignInTimestamp')
        const { uid } = authUser
        if (!isExpired(email, timestampString)) {
          // email check is done in isExpired()
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const account = getAccountByEmail(email!)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setupMixpanelUser(uid, email!, account?.name || 'unknown')
          setCurrentUser(authUser)
        } else {
          signOut()
        }
      } else {
        setCurrentUser(null)
      }
    })
  }, [])
  return currentUser
}

const useSignInFromEmailLink = () => {
  const { t } = useTranslation()
  const unknownErrorMsg = t('errors.unknown', { supportEmail: constants.SUPPORT_EMAIL_ADDRESS })
  const wrongBrowserMsg = t('authentication.openWithSameBrowser')
  const [signInError, setSignInError] = React.useState<string | undefined>(undefined)
  React.useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        setSignInError(wrongBrowserMsg)
      } else {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error)
            setSignInError(unknownErrorMsg)
          })
      }
    }
  }, [unknownErrorMsg, wrongBrowserMsg])
  return signInError
}

type ValueType = {
  email: string
}

const handleSubmit = async (values: ValueType, { setSubmitting }: FormikHelpers<ValueType>) => {
  const { email } = values
  setSubmitting(true)
  try {
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  window.localStorage.setItem('emailForSignIn', email)
  window.localStorage.setItem('emailForSignInTimestamp', new Date().toString())
  setSubmitting(false)
}

export default function useFirebaseLogin() {
  const currentUser = useAuthStateListener()
  const signInError = useSignInFromEmailLink()
  const isAuthenticatedUser = useValidateUserAuthorization(currentUser)

  return {
    isAuthenticatedUser,
    email: currentUser?.email,
    signOut,
    error: signInError,
    handleSubmit,
  }
}

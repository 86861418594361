import { Template, ThemeName, Language } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const helen: Account = {
  name: 'Helen',
  emailDomain: 'helen.fi',
  authorizeEveryoneFromDomain: true,
  allowedTemplates: [Template.Summary],
  allowedThemes: [ThemeName.Apexheat],
  reportLanguages: [Language.Fi],
}

export default helen

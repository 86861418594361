import { PropsWithChildren, useEffect, useState } from 'react'
import { Box, FormControl, Input, Typography } from '@material-ui/core'
import { FieldProps } from 'formik'
import { trackFieldChange } from '../mixpanel'

type Props = FieldProps & {
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  numeric?: boolean
}

export default function CustomTextField(props: PropsWithChildren<Props>) {
  const { disabled, field, form, label, fullWidth, numeric } = props
  const [inputValue, setInputValue] = useState('')
  useEffect(() => {
    setInputValue((Math.round(Number(field.value) * 100) / 100).toString() || '')
  }, [field.value])
  return (
    <Box width={fullWidth ? '100%' : '48%'}>
      <FormControl disabled={form.isSubmitting || disabled} fullWidth>
        <Typography id={field.name} variant="caption" color="textSecondary">
          {label || field.name}
        </Typography>
        <Input
          fullWidth
          disabled={form.isSubmitting || disabled}
          readOnly={disabled}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          margin="dense"
          onBlur={(event) => {
            trackFieldChange(field.name)
            const newValue = numeric ? Number(event.target.value) : event.target.value
            if (field.name === 'number_of_habitants' && (!newValue || newValue === '0')) {
              form.setFieldValue(field.name, null)
            } else {
              form.setFieldValue(field.name, newValue)
            }
          }}
          inputProps={{
            type: numeric ? 'tel' : 'text',
            'aria-labelledby': field.name,
          }}
        />
      </FormControl>
    </Box>
  )
}

import React from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Typography, makeStyles, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TextField } from 'formik-material-ui'
import useDebouncedSearch from './useDebouncedSearch'
import AbsolutePositionedLoader from './AbsolutePositionedLoader'
import PropertiesList from './PropertiesList'
import { BuildingDataModel } from '../api-types'
import { trackAddressSearch } from '../mixpanel'

const validationSchema = Yup.object({
  Nimi: Yup.string().default(''),
})

const initialValues = validationSchema.getDefault()

const useStyles = makeStyles({
  formContainer: {
    position: 'relative',
  },
})

type Props = {
  onSuccess: React.Dispatch<React.SetStateAction<BuildingDataModel | undefined>>
}

export default function AddressSearch({ onSuccess }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'address' })
  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    setSubmitting(false)
  }
  const { value, setValue, isLoading, error, properties, resetProperties } = useDebouncedSearch()

  const [passingValuesToReportForm, setPassingValuesToReport] = React.useState(false)

  const onPropertySelect = async (property: BuildingDataModel) => {
    setPassingValuesToReport(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    onSuccess(property)
    trackAddressSearch()
    resetProperties()
    setValue('')
    setPassingValuesToReport(false)
  }

  const classes = useStyles()

  return (
    <Box position="relative" flexGrow={1}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={classes.formContainer}>
            <Field
              name="Nimi"
              label={t('insert')}
              component={TextField}
              autoComplete="off"
              fullWidth
              autoFocus
              id="Nimi"
              value={value}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
            />
            <AbsolutePositionedLoader
              enabled={isSubmitting || isLoading || passingValuesToReportForm}
            />
          </Form>
        )}
      </Formik>
      {error && <Typography color="error">{error}</Typography>}
      <PropertiesList
        properties={properties}
        onSelect={onPropertySelect}
        disabled={isLoading || passingValuesToReportForm}
      />
    </Box>
  )
}

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Box, Typography, Button } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { useTranslation } from 'react-i18next'
import App from './App'
import useFirebaseLogin from './useFirebaseLogin'
import AbsolutePositionedLoader from './AddressSearch/AbsolutePositionedLoader'
import constants from './constants-generated.json'

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
})

export default function LoginScreens() {
  const { t } = useTranslation()
  const { isAuthenticatedUser, email, error, handleSubmit, signOut } = useFirebaseLogin()
  if (isAuthenticatedUser && email) return <App email={email} />
  if (isAuthenticatedUser === false)
    return (
      <Box paddingTop="16px">
        <Typography color="error">
          {t('authentication.accountNotAllowed', {
            account: email,
            supportEmail: constants.SUPPORT_EMAIL_ADDRESS,
          })}
        </Typography>
        <Button variant="contained" color="primary" onClick={signOut} size="large">
          {t('authentication.logout')}
        </Button>
      </Box>
    )
  return (
    <Box paddingTop="16px">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitCount, values }) => (
          <Form>
            <Field
              name="email"
              label={t('email')}
              component={TextField}
              autoFocus
              id="email"
              fullWidth
            />
            <AbsolutePositionedLoader enabled={isSubmitting} />
            {error && (
              <Typography color="error">
                {t('errors.unknown', { supportEmail: constants.SUPPORT_EMAIL_ADDRESS })}
              </Typography>
            )}
            {submitCount > 0 && (
              <Typography>{t('authentication.confirmEmail', { email: values.email })}</Typography>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

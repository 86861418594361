import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Container } from '@material-ui/core'
import LoginScreens from './LoginScreens'
import BottomFooter from './BottomFooter'

import './i18n'

document.body.style.backgroundColor = '#F5F5F5'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Container maxWidth="sm">
        <LoginScreens />
        <BottomFooter />
      </Container>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

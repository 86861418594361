import { omit, values } from 'lodash'
import { Template, ThemeName, Language } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const yourspace: Account = {
  name: 'Yourspace Oy',
  emailDomain: 'yourspace.fi',
  allowFileDownload: true,
  allowSolar: true,
  authorizeEveryoneFromDomain: true,
  allowedTemplates: [Template.EnergyAnalysis, ...values(omit(Template, 'EnergyAnalysis'))],
  allowedThemes: [ThemeName.Apexheat, ...values(omit(ThemeName, 'Apexheat'))],
  reportLanguages: [Language.Fi, Language.En],
}

export default yourspace

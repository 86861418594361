/* eslint-disable @typescript-eslint/naming-convention */
import React, { Dispatch, SetStateAction } from 'react'
import { useFormikContext } from 'formik'
import { calculateEnergyConsumption } from '../lib/api'
import { EnergyConsumptionApiInput } from '../api-types'

type Props = {
  setLoadingStatus: Dispatch<SetStateAction<boolean>>
}

export default function AutoUpdateEnergyConsumption({ setLoadingStatus }: Props) {
  const { values, setFieldValue } = useFormikContext<EnergyConsumptionApiInput>()
  const {
    building_type,
    construction_decade,
    street_address,
    has_heat_recovery,
    has_mechanical_ventilation,
    heated_floor_area,
    heating_fuel,
    number_of_ac_machines,
    number_of_habitants,
    number_of_aa_units,
  } = values

  const updateEnergyConsumption = React.useCallback(async () => {
    setLoadingStatus(true)
    const { elec, exhaust_airflow, flow_temperature, heat, hotwater, heat_recovery_capacity } =
      await calculateEnergyConsumption({
        street_address,
        building_type,
        construction_decade,
        has_heat_recovery,
        has_mechanical_ventilation,
        heated_floor_area,
        heating_fuel,
        number_of_ac_machines,
        number_of_habitants,
        number_of_aa_units,
      })

    // form values are in MWh
    setFieldValue('elec_consumption_mwh', elec / 1000)
    setFieldValue('exhaust_airflow', exhaust_airflow)
    setFieldValue('flow_temperature', flow_temperature)
    setFieldValue('heat_consumption_mwh', heat / 1000)
    setFieldValue('hotwater_consumption_mwh', hotwater / 1000)
    setFieldValue('heat_recovery_capacity', heat_recovery_capacity)
    setLoadingStatus(false)
    return null
  }, [
    building_type,
    has_heat_recovery,
    has_mechanical_ventilation,
    setLoadingStatus,
    setFieldValue,
    construction_decade,
    street_address,
    heated_floor_area,
    heating_fuel,
    number_of_ac_machines,
    number_of_habitants,
    number_of_aa_units,
  ])

  React.useEffect(() => {
    updateEnergyConsumption()
  }, [updateEnergyConsumption])
  return null
}

import React from 'react'
import { makeStyles, Paper, List, ListItem, ListItemText } from '@material-ui/core'
import { BuildingDataModel } from '../api-types'

type Props = {
  properties: BuildingDataModel[]
  onSelect: (property: BuildingDataModel) => void
  disabled: boolean
}

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    width: '100%',
    maxHeight: '275px',
    overflow: 'scroll',
    zIndex: 1,
  },
})

export default function PropertiesList({ properties, onSelect, disabled }: Props) {
  const classes = useStyles()
  if (properties.length === 0) return null
  return (
    <Paper className={classes.container} elevation={3} square>
      <List dense>
        {properties.map((property) => (
          <ListItem
            disabled={disabled}
            button
            key={property.street_address}
            onClick={() => {
              onSelect(property)
            }}
          >
            <ListItemText primary={property.street_address} />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

import React from 'react'
import { BuildingDataModel } from './api-types'

export const SEARCH_BY_ADDRESS = 'search-by-address'
export const SEARCH_BY_BUILDING_DETAILS = 'search-by-building-details'

const ALWAYS_ALLOWED = [SEARCH_BY_ADDRESS]

export default function useDefineInitialState() {
  const [buildingDetailsFromSearch, updateBuildingDetails] = React.useState<BuildingDataModel>()

  const [expandedForm, setExpanded] = React.useState(SEARCH_BY_ADDRESS)

  const handleChange = (panel: string) => (_: unknown, isExpanded: boolean) => {
    // disable building details form if address is not searched first
    if (ALWAYS_ALLOWED.includes(panel) || !!buildingDetailsFromSearch?.street_address) {
      setExpanded(isExpanded ? panel : '')
    }
  }

  React.useEffect(() => {
    if (buildingDetailsFromSearch) {
      setExpanded(SEARCH_BY_BUILDING_DETAILS)
    }
  }, [buildingDetailsFromSearch])

  return {
    buildingDetailsFromSearch,
    handleChange,
    expandedForm,
    updateBuildingDetails,
  }
}

import { Box, Button, ButtonGroup, LinearProgress, makeStyles, MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CustomSelectField } from '../fields'
import { Template, ThemeName, Language } from '../pdfs-graphql-generated'

type Props = {
  disabled: boolean
  isSubmitting: boolean
  onPrimaryButtonClick: () => Promise<void>
  primaryLabel: string
  templates: Array<Template>
  themes: Array<ThemeName>
  languages: Array<Language>
}

export default function ActionButtons({
  disabled,
  isSubmitting,
  onPrimaryButtonClick,
  primaryLabel,
  templates,
  themes,
  languages,
}: Props) {
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <Box position="relative" width="100%">
      <Box display="flex" flexWrap="wrap" className={styles.fields}>
        {templates.length > 1 && (
          <Field
            name="template"
            label={t('fields.templates.fieldName')}
            component={CustomSelectField}
          >
            {map(templates, (template) => (
              <MenuItem key={template} value={template}>
                {t(`fields.templates.${template}`)}
              </MenuItem>
            ))}
          </Field>
        )}
        {themes.length > 1 && (
          <Field
            name="theme_name"
            label={t('fields.themes.fieldName')}
            component={CustomSelectField}
          >
            {map(themes, (theme) => (
              <MenuItem key={theme} value={theme}>
                {t(`fields.themes.${theme}`)}
              </MenuItem>
            ))}
          </Field>
        )}
        {languages.length > 1 && (
          <Field
            name="language"
            label={t('fields.languages.fieldName')}
            component={CustomSelectField}
          >
            {map(languages, (language) => (
              <MenuItem key={language} value={language}>
                {t(`fields.languages.${language}`)}
              </MenuItem>
            ))}
          </Field>
        )}
      </Box>
      <ButtonGroup
        fullWidth
        variant="contained"
        size="large"
        aria-label="action buttons group"
        disabled={disabled}
      >
        <Button color="primary" aria-label={primaryLabel} onClick={onPrimaryButtonClick}>
          {primaryLabel}
        </Button>
      </ButtonGroup>
      {isSubmitting && <Loader />}
    </Box>
  )
}

const useStyles = makeStyles({
  fields: {
    columnGap: 16,
  },
})

const useLoaderStyles = makeStyles({
  loader: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

function Loader() {
  const styles = useLoaderStyles()
  return (
    <Box position="absolute" bottom={0} width="100%">
      <LinearProgress className={styles.loader} />
    </Box>
  )
}

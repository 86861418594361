/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser'

// EVENTS
const DOWNLOAD_REPORT = 'Downloaded PDF Report'
const SELECT_ADDRESS = 'Selected an address for report generation'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string)
const isProduction = process.env.NODE_ENV === 'production'

export const trackDownloadPDF = () => {
  if (isProduction) {
    mixpanel.track(DOWNLOAD_REPORT)
  } else {
    console.log(`mixpanel track ${DOWNLOAD_REPORT}`)
  }
}

export const trackAddressSearch = () => {
  if (isProduction) {
    mixpanel.track(SELECT_ADDRESS)
  } else {
    console.log(`mixpanel track ${SELECT_ADDRESS}`)
  }
}

export const setupMixpanelUser = (uid: string, email: string, company: string) => {
  const [name] = email.split('@')
  mixpanel.identify(uid)
  mixpanel.people.set({ $name: name, $email: email })
  mixpanel.set_group('company', company)
}

export const trackFieldChange = (id: string) => {
  mixpanel.track(`changed field ${id}`)
}

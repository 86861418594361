import { Field } from 'formik'
import { MenuItem } from '@material-ui/core'
import { map, min, max } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CustomSelectField } from '../fields'

type Props = {
  disabled: boolean
}

export default function ConstructionDecade({ disabled }: Props) {
  const { t } = useTranslation()
  return (
    <Field
      name="construction_decade"
      label={t('fields.constructionDecade.name')}
      disabled={disabled}
      component={CustomSelectField}
    >
      {/* Changing this field sets constuction year to undefined so that it's not used in report */}
      {map(decades, (decade) => (
        <MenuItem key={decade} value={decade}>
          {decade} {decade === min(decades) ? t('fields.constructionDecade.older') : null}{' '}
          {decade === max(decades) ? t('fields.constructionDecade.newer') : null}
        </MenuItem>
      ))}
    </Field>
  )
}

const decades = [1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010] as const

export type Decade = (typeof decades)[number]

import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import { FieldProps } from 'formik'
import { trackFieldChange } from '../mixpanel'

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
})

type Props = {
  label?: string
  disabled?: boolean
}

const CustomCheckboxField = ({ field, disabled, form, label }: FieldProps & Props) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.container}
      disabled={disabled}
      control={
        <Checkbox
          checked={field.value}
          onChange={(event) => {
            trackFieldChange(field.name)
            form.setFieldValue(field.name, event.target.checked)
          }}
          name={field.name}
          color="primary"
        />
      }
      label={label}
    />
  )
}

export default CustomCheckboxField

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Field, useFormikContext } from 'formik'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CustomSelectField, CustomSliderField, CustomTextField } from '../fields'
import { PdfApiInput } from '../api-types'

type Props = {
  disabled: boolean
}

export default function OtherParameters({ disabled }: Props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { values } = useFormikContext<PdfApiInput>()

  return (
    <Accordion className={classes.accordion} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('fields.otherParameters')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Field
          name="heat_pump_coverage"
          label={t('fields.heatPumpCoverage')}
          component={CustomSliderField}
          disabled={disabled}
          percentage
          step={0.01}
          min={0.4}
          max={1.0}
          showInputField
        />
        {values.flow_temperature && (
          <Field
            name="flow_temperature"
            label={t('fields.flowTemperature')}
            component={CustomSliderField}
            max={100}
            step={1}
            min={20}
            showInputField
          />
        )}
        <Field
          name="cop_multiplier"
          label={t('fields.copMultiplier')}
          component={CustomSliderField}
          max={1.3}
          min={0.7}
          step={0.05}
          showInputField
        />
        <Field
          name="calculation_lifetime"
          label={t('fields.calculationLifecycle')}
          component={CustomSelectField}
        >
          {map([15, 20, 25, 30], (year) => (
            <MenuItem key={year} value={year}>
              {t('numberOfYears', { count: year })}
            </MenuItem>
          ))}
        </Field>
        <Field
          name="electricity_annual_increase"
          label={t('fields.electricityAnnualIncrease')}
          component={CustomSliderField}
          percentage
          step={0.001}
          min={-0.1}
          max={0.1}
        />
        <Field
          name="heating_annual_increase"
          label={t('fields.heatingAnnualIncrease')}
          component={CustomSliderField}
          percentage
          step={0.001}
          min={-0.1}
          max={0.1}
        />
        <Field
          name="interest_per_year"
          label={t('fields.interestPerYear')}
          component={CustomSliderField}
          percentage
          max={0.1}
          min={0}
          step={0.005}
        />
        <Field
          name="loan_maturity_years"
          label={t('fields.loanMaturityYears')}
          component={CustomSliderField}
          min={1}
          step={1}
          max={30}
        />
        <Field
          name="year_now_spot_price"
          label={t('fields.yearNowSpotPrice')}
          component={CustomSliderField}
          max={200}
          min={20}
        />
        <Field
          name="number_of_aa_units"
          label={t('fields.numberOfAaUnits')}
          component={CustomTextField}
          type="number"
        />
        <Field
          name="number_of_habitants"
          label={t('fields.numberOfHabitants')}
          component={CustomTextField}
          type="number"
        />
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: theme.spacing(4),
  },
  details: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}))

/* eslint-disable react/prop-types */
import React from 'react'
import { FormControl, InputLabel, Select, makeStyles } from '@material-ui/core'
import { FieldProps } from 'formik'
import { trackFieldChange } from '../mixpanel'

const useStyles = makeStyles({
  container: {
    width: '48%',
  },
})

type Props = {
  label?: string
  disabled?: boolean
}

const CustomSelectField: React.FunctionComponent<FieldProps & Props> = (props) => {
  const { children, field, form, label, disabled } = props
  const classes = useStyles()
  return (
    <FormControl disabled={form.isSubmitting || disabled} className={classes.container}>
      <InputLabel htmlFor={field.name}>{label || field.name}</InputLabel>
      <Select
        value={field.value}
        onChange={(event) => {
          trackFieldChange(field.name)
          form.setFieldValue(field.name, event.target.value)
          if (field.name === 'construction_decade') {
            form.setFieldValue('construction_year', undefined)
          }
        }}
        inputProps={{
          id: field.name,
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default CustomSelectField

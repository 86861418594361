import { Box, Typography } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { PdfApiInput } from '../api-types'
import constants from '../constants-generated.json'
import { CustomCheckboxField, CustomTextField } from '../fields'

type Props = {
  disabled: boolean
}

export default function Solar({ disabled }: Props) {
  const { t } = useTranslation()
  const { values } = useFormikContext<PdfApiInput>()

  return (
    <>
      <Field
        name="calculate_solar"
        component={CustomCheckboxField}
        label={t('fields.calculateSolar')}
        disabled={disabled}
      />
      {values.calculate_solar && (
        <Box>
          <Field
            name="suitable_roof_area_for_solar"
            label={t('fields.suitableAreaForSolar')}
            component={CustomTextField}
            disabled={disabled}
            step={1}
            min={constants.MINIMUM_SOLAR_SYSTEM_PANEL_AREA}
            fullWidth
            showInputField
          />
          {values.suitable_roof_area_for_solar < constants.MINIMUM_SOLAR_SYSTEM_PANEL_AREA && (
            <Typography color="error">{t('errors.roofTooSmallForSolar')}</Typography>
          )}
        </Box>
      )}
    </>
  )
}

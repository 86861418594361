import { Field, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PdfApiInput } from '../api-types'
import { CustomTextField } from '../fields'
import { updateNumberOfAcMachines } from '../lib/api'

type Props = {
  disabled: boolean
}

export default function NumberOfAcMachines({ disabled }: Props) {
  const { t } = useTranslation()
  useAutoUpdateNumberOfAcMachines()
  return (
    <Field
      name="number_of_ac_machines"
      label={t('fields.numberOfAcMachines')}
      component={CustomTextField}
      disabled={disabled}
    />
  )
}

const useAutoUpdateNumberOfAcMachines = () => {
  const { values, setFieldValue } = useFormikContext<PdfApiInput>()
  const {
    building_type: buildingType,
    construction_decade: year,
    has_mechanical_ventilation: hasMechanicalVentilation,
    heated_floor_area: floorArea,
    heating_fuel: fuel,
    street_address: address,
  } = values

  useEffect(() => {
    updateNumberOfAcMachines({
      address,
      building_type: buildingType,
      construction_decade: year,
      has_mechanical_ventilation: hasMechanicalVentilation,
      heated_floor_area: floorArea,
      heating_fuel: fuel,
    }).then(({ number_of_ac_machines }) => {
      setFieldValue('number_of_ac_machines', number_of_ac_machines)
    })
  }, [setFieldValue, address, buildingType, year, floorArea, fuel, hasMechanicalVentilation])
}

import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type Props = {
  enabled: boolean
}

export default function UpdateParamaetersLoader({ enabled }: Props) {
  const { t } = useTranslation()
  return enabled ? (
    <Typography color="textSecondary" variant="caption">
      {t('loading')}
    </Typography>
  ) : null
}

import { useMemo } from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { isResidential } from './BuildingType'
import { CustomTextField } from '../fields'
import { BuildingType } from '../api-types'

type Props = {
  buildingType: BuildingType
  disabled: boolean
}

export default function FloorArea({ buildingType, disabled }: Props) {
  const { t } = useTranslation()
  const floorAreaDescription = useMemo(
    () => (isResidential(buildingType) ? t('fields.floorArea.net') : t('fields.floorArea.gross')),
    [buildingType, t]
  )
  return (
    <Field
      name="heated_floor_area"
      label={`${t('fields.floorArea.heated')} - ${floorAreaDescription}`}
      component={CustomTextField}
      disabled={disabled}
    />
  )
}

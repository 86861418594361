import { map } from 'lodash'
import { Field } from 'formik'
import { styled } from '@material-ui/styles'
import MuiMenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import { ReportTypeInput } from '../api-types'
import { CustomSelectField } from '../fields'

const FIELD_NAME = 'report_type'
const reportTypes: ReportTypeInput[] = ['gsaw', 'gs', 'aw']

type Props = {
  disabled: boolean
}

export default function ReportTypeField({ disabled }: Props) {
  const { t } = useTranslation()
  return (
    <Field
      name={FIELD_NAME}
      label={t('fields.reportType')}
      disabled={disabled}
      component={CustomSelectField}
    >
      {map(reportTypes, (reportType) => (
        <MenuItem key={reportType} value={reportType} disabled={disabled}>
          {t(`reportTypes.${reportType}`)}
        </MenuItem>
      ))}
    </Field>
  )
}

const MenuItem = styled(MuiMenuItem)({
  color: (props) => (props.disabled ? 'grey' : 'inherit'),
})

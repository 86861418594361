export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
};

export type Assumptions = {
  boreHoleRadius: Scalars['Float'];
  buildingElecConnectionCapacity: Scalars['Int'];
  buildingElecConsumptionWithRecommendedHeatPump: Scalars['Float'];
  buildingElectricityConsumption: Scalars['Float'];
  buildingHotwaterConsumption: Scalars['Int'];
  buildingPeakPower: Scalars['Int'];
  buildingRecommendedHeatPumpElecConsumption: Scalars['Float'];
  calculateWithHeatRecovery: Scalars['Boolean'];
  drillStartDistanceFromBuilding: Scalars['Float'];
  drillStartRadius: Scalars['Float'];
  elecAnnualIncrease: Scalars['Float'];
  electricityEmissionsFactorSource: Scalars['String'];
  exhaustAirflow: Scalars['Float'];
  flowTemperature: Scalars['Int'];
  heatAnnualIncrease: Scalars['Float'];
  heatingEmissionsFactorSource: Scalars['String'];
  isDaikinCampaign: Scalars['Boolean'];
  lifeCycle: Scalars['Int'];
  loanInterest: Scalars['Float'];
  loanMaturity: Scalars['Int'];
  vatPercentage: Scalars['Float'];
  yearNow: Scalars['Int'];
  yearNowElecTotalPriceWithVat: Scalars['Int'];
  yearNowSpotPriceVat0: Scalars['Int'];
  yearNowSpotPriceWithVat: Scalars['Int'];
};

export type BuildingDetails = {
  buildingFootprint: Scalars['Int'];
  buildingId: Scalars['String'];
  buildingType: BuildingType;
  city: Scalars['String'];
  constructionAgeBand: ConstructionAgeBand;
  constructionYear: Scalars['Int'];
  coordinates: Coordinates;
  discountRate: Scalars['Float'];
  districtHeatingProvider: Scalars['String'];
  electricityDistributionProvider: Scalars['String'];
  hasAlreadyHp: Scalars['Boolean'];
  hasHeatRecovery: Scalars['Boolean'];
  hasMechanicalVentilation: Scalars['Boolean'];
  heatedFloorArea: Scalars['Int'];
  heatingDistributionMethod: HeatingDistributionMethod;
  heatingFuel: HeatingFuel;
  isGroundwaterArea?: InputMaybe<Scalars['Boolean']>;
  isUndergroundZoningArea?: InputMaybe<Scalars['Boolean']>;
  lotSize?: InputMaybe<Scalars['Int']>;
  numberOfAcMachines: Scalars['Int'];
  numberOfHabitants: Scalars['Int'];
  postCode: Scalars['String'];
  roofArea: Scalars['Int'];
  source: BuildingDetailsSource;
  street: Scalars['String'];
  suitableAreaForSolarPanels: Scalars['Int'];
};

export enum BuildingDetailsSource {
  MunicipalityData = 'MUNICIPALITY_DATA',
  SpatialData = 'SPATIAL_DATA'
}

export enum BuildingType {
  Apartment = 'APARTMENT',
  Detached = 'DETACHED',
  Education = 'EDUCATION',
  HealthCare = 'HEALTH_CARE',
  Industrial = 'INDUSTRIAL',
  Logistics = 'LOGISTICS',
  Office = 'OFFICE',
  Other = 'OTHER',
  Parking = 'PARKING',
  Public = 'PUBLIC',
  Retail = 'RETAIL',
  Terraced = 'TERRACED'
}

export type ChartData = {
  series: Array<ChartSeries>;
  title?: InputMaybe<Scalars['String']>;
  unit: Unit;
};

export type ChartDataPoint = {
  label: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
};

export enum ChartLabel {
  AcMachines = 'AC_MACHINES',
  DebtCosts = 'DEBT_COSTS',
  Design = 'DESIGN',
  Drilling = 'DRILLING',
  ElectricityUpgrade = 'ELECTRICITY_UPGRADE',
  HeatingDistributionUpgrade = 'HEATING_DISTRIBUTION_UPGRADE',
  HeatEnergy = 'HEAT_ENERGY',
  HeatExchanger = 'HEAT_EXCHANGER',
  HeatPump = 'HEAT_PUMP',
  HeatRecovery = 'HEAT_RECOVERY',
  InstallationAndComponents = 'INSTALLATION_AND_COMPONENTS',
  Maintenance = 'MAINTENANCE',
  SelfConsumption = 'SELF_CONSUMPTION',
  SoldConsumption = 'SOLD_CONSUMPTION',
  Vat = 'VAT'
}

export type ChartSeries = {
  data: Array<ChartDataPoint>;
  name: Scalars['String'];
};

export enum ConstructionAgeBand {
  Decade_1930AndEarlier = 'DECADE_1930_AND_EARLIER',
  Decade_1940 = 'DECADE_1940',
  Decade_1950 = 'DECADE_1950',
  Decade_1960 = 'DECADE_1960',
  Decade_1970 = 'DECADE_1970',
  Decade_1980 = 'DECADE_1980',
  Decade_1990 = 'DECADE_1990',
  Decade_2000 = 'DECADE_2000',
  Decade_2010AndLater = 'DECADE_2010_AND_LATER'
}

export type Coordinates = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Cost = {
  cost: Scalars['Float'];
  label: ChartLabel;
};

export type CostItem = {
  costs: Array<Cost>;
  type: Scalars['String'];
};

export type Costs = {
  items: Array<CostItem>;
  unit: Scalars['String'];
};

export type CurrentHeatingDetails = {
  annualTotalHeatingCost: Scalars['Int'];
  costPerConsumption: Scalars['Int'];
  emissions: Scalars['Int'];
};

export enum DrillingNotPossibleReason {
  IsGroundwaterArea = 'IS_GROUNDWATER_AREA',
  IsUndergroundZoningArea = 'IS_UNDERGROUND_ZONING_AREA',
  NotEnoughAreaForBoreHoles = 'NOT_ENOUGH_AREA_FOR_BORE_HOLES'
}

export type DrillingParameters = {
  boreHoleDepth: Scalars['Int'];
  boreHoleLocations: Array<Coordinates>;
  boreHoleProductionPerMeter: Scalars['Int'];
  boreHolesDepthTotal: Scalars['Int'];
  drillingIsNotPossible: Scalars['Boolean'];
  drillingIsNotPossibleReasons: Array<InputMaybe<DrillingNotPossibleReason>>;
  drillingPossibleWithPreferredDepth: Scalars['Boolean'];
  maxNumberOfBoreHolesForLot?: InputMaybe<Scalars['Int']>;
  preferredPumpType: HeatPump;
  requiredNumberOfBoreHoles: Scalars['Float'];
};

export enum EClassCategory {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G'
}

export type EClassInput = {
  current: EClassCategory;
  investments: Array<EClassInvestment>;
  potential: EClassCategory;
};

export type EClassInvestment = {
  eClass?: InputMaybe<EClassCategory>;
  eNumber: Scalars['Int'];
  name: Scalars['String'];
};

export type EnergyEfficiencyInput = {
  heatLosses: HeatLosses;
  investments: Array<EnergyEfficiencyInvestment>;
  recommendation: EnergyEfficiencyRecommendation;
};

export type EnergyEfficiencyInvestment = {
  capex: Scalars['Int'];
  capexPerSquareMeter: Scalars['Float'];
  consumptionReduction: Scalars['Float'];
  consumptionReductionFromTotal: Scalars['Float'];
  costReduction: Scalars['Float'];
  irr: Scalars['Float'];
  name: EnergyEfficiencyInvestmentType;
  npv: Scalars['Int'];
  paybacktime?: InputMaybe<Scalars['Float']>;
};

export enum EnergyEfficiencyInvestmentType {
  Floors = 'FLOORS',
  Roof = 'ROOF',
  Walls = 'WALLS',
  WindowsAndDoors = 'WINDOWS_AND_DOORS'
}

export type EnergyEfficiencyRecommendation = {
  hasRecommendations: Scalars['Boolean'];
  investments: Array<EnergyEfficiencyInvestment>;
  totalAnnualConsumptionReduction: Scalars['Int'];
  totalAnnualConsumptionReductionPercentage: Scalars['Float'];
  totalAnnualSavings: Scalars['Int'];
};

export type FeatheryFeedbackFormHiddenFields = {
  __typename?: 'FeatheryFeedbackFormHiddenFields';
  address?: Maybe<Scalars['String']>;
  building_type?: Maybe<Scalars['String']>;
  construction_year?: Maybe<Scalars['String']>;
  floor_area?: Maybe<Scalars['String']>;
  heat_consumption?: Maybe<Scalars['String']>;
  heating_method?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  report_created_at?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

export type HeatLosses = {
  currentConsumption: Array<ThermalHeatInput>;
  currentCosts: Array<ThermalHeatInput>;
  potentialConsumption: Array<ThermalHeatInput>;
  potentialCosts: Array<ThermalHeatInput>;
  uValues: Array<UValueInput>;
};

export enum HeatPump {
  AaHp = 'AA_HP',
  AaHpWithHr = 'AA_HP_WITH_HR',
  AwHp = 'AW_HP',
  AwHpHybrid = 'AW_HP_HYBRID',
  GsHp = 'GS_HP',
  GsHpWithHr = 'GS_HP_WITH_HR',
  HrHp = 'HR_HP'
}

export type HeatingAnalysisData = {
  annualHeatConsumption: Scalars['Int'];
  currentHeatingFuel: HeatingFuel;
  heatCostsChart: ChartData;
  heatEmissionsChart: ChartData;
  heatEnergyChart: ChartData;
  longTermHeatingCosts?: InputMaybe<ChartData>;
};

export enum HeatingDistributionMethod {
  Air = 'AIR',
  Water = 'WATER'
}

export enum HeatingFuel {
  AwHp = 'AW_HP',
  DistrictHeating = 'DISTRICT_HEATING',
  Electricity = 'ELECTRICITY',
  Gas = 'GAS',
  GsHp = 'GS_HP',
  Oil = 'OIL',
  Wood = 'WOOD'
}

export type InvestmentDetail = {
  annualSavings: Scalars['Int'];
  annualTotalHeatingCost: Scalars['Int'];
  capacity: Scalars['Int'];
  costPerConsumption: Scalars['Int'];
  drillingParameters?: InputMaybe<DrillingParameters>;
  electricBoilerCapacity: Scalars['Int'];
  emissions: Scalars['Int'];
  irr?: InputMaybe<Scalars['Float']>;
  npv: Scalars['Int'];
  numberOfElectricBoilers: Scalars['Int'];
  numberOfHotWaterHeaters: Scalars['Int'];
  numberOfPumps: Scalars['Int'];
  paybacktime?: InputMaybe<Scalars['Float']>;
  requiredElectricityConnectionCapacity: Scalars['Int'];
  requiredFloorAreaForMachinery: Scalars['Float'];
  roi: Scalars['Float'];
  scop: Scalars['Float'];
  totalCapex: Scalars['Int'];
  type: HeatPump;
};

export type InvestmentDetails = {
  currentHeatingMethod: CurrentHeatingDetails;
  investmentCosts: Costs;
  maintenanceCosts: Costs;
  recommendation: Recommendation;
  sensitivityAnalysis: Array<SensitivityScenarioResult>;
  summaryTable: Array<InvestmentDetail>;
};

export enum Language {
  En = 'EN',
  Fi = 'FI'
}

export type PdfInput = {
  assumptions: Assumptions;
  buildingDetails: BuildingDetails;
  drilling: DrillingParameters;
  eClass?: InputMaybe<EClassInput>;
  energyEfficiency?: InputMaybe<EnergyEfficiencyInput>;
  heatingAnalysisData: HeatingAnalysisData;
  investmentDetails: InvestmentDetails;
  language: Language;
  solar?: InputMaybe<SolarInput>;
  template: Template;
  theme: Theme;
};

export type PdfSuccess = {
  __typename?: 'PdfSuccess';
  buffer: Scalars['Byte'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  pdf: PdfSuccess;
};


export type QueryPdfArgs = {
  input: PdfInput;
};

export type Recommendation = {
  annualSavings: Scalars['Int'];
  debtCost: Scalars['Float'];
  electricityTariff: Scalars['String'];
  heatPump?: InputMaybe<HeatPump>;
  heatPumpWithMaxNpv: HeatPump;
};

export type SensitivityScenarioResult = {
  capex: Scalars['Int'];
  capexFactor: Scalars['Float'];
  elecPriceFactor: Scalars['Float'];
  elecPriceLabel: Scalars['String'];
  heatPriceFactor: Scalars['Float'];
  heatPriceLabel: Scalars['String'];
  npv?: InputMaybe<Scalars['Int']>;
  paybacktime?: InputMaybe<Scalars['Float']>;
  recommendedHeatpump: Scalars['String'];
};

export type SolarAssumptions = {
  areaPerPanel: Scalars['Float'];
  coordinates: Coordinates;
  distanceFromRooftopEdge: Scalars['Float'];
  irradiation: Scalars['Int'];
  panelEfficiency: Scalars['Float'];
  powerPerPanel: Scalars['Float'];
  sparePartsRenewalFactor: Scalars['Float'];
  sparePartsRenewalYear: Scalars['Int'];
};

export type SolarInput = {
  assumptions: SolarAssumptions;
  isSolarRecommended: Scalars['Boolean'];
  recommendation: SolarSystem;
  recommendationWithHeatpump: SolarSystem;
  sensitivityAnalysis: Array<SolarSensitivityScenarioResult>;
  spotPriceChartData: ChartData;
  systems: Array<SolarSystem>;
};

export type SolarSensitivityScenarioResult = {
  capex: Scalars['Int'];
  capexFactor: Scalars['Float'];
  elecPriceFactor: Scalars['Float'];
  elecPriceLabel: Scalars['String'];
  npv?: InputMaybe<Scalars['Int']>;
  paybacktime?: InputMaybe<Scalars['Float']>;
  selfConsumption: Scalars['Float'];
};

export type SolarSystem = {
  capex: Scalars['Int'];
  eNumberReduction: Scalars['Int'];
  emissionReduction: Scalars['Int'];
  emissionReductionPercent: Scalars['Float'];
  installedHeatpump?: InputMaybe<HeatPump>;
  irr?: InputMaybe<Scalars['Float']>;
  maintenanceCostChange: Scalars['Float'];
  maintenanceCostChangeWithDebt: Scalars['Float'];
  monthlyProduction: ChartData;
  npv: Scalars['Int'];
  panelArea: Scalars['Int'];
  panelAreaOfRoof?: InputMaybe<Scalars['Float']>;
  panelCount: Scalars['Int'];
  paybacktime?: InputMaybe<Scalars['Float']>;
  peakPower: Scalars['Float'];
  production: Scalars['Int'];
  roi: Scalars['Float'];
  savings: Scalars['Int'];
  selfConsumption: Scalars['Float'];
  selfConsumptionWithHeatpump: Scalars['Float'];
};

export enum Template {
  ApexHeatAppPublic = 'APEX_HEAT_APP_PUBLIC',
  EnergyAnalysis = 'ENERGY_ANALYSIS',
  Summary = 'SUMMARY'
}

export type Theme = {
  name: ThemeName;
};

export enum ThemeName {
  Apexheat = 'APEXHEAT',
  Are = 'ARE',
  Daikin = 'DAIKIN',
  Geonova = 'GEONOVA',
  One1 = 'ONE1',
  Raksystems = 'RAKSYSTEMS',
  Retta = 'RETTA',
  Saaristo = 'SAARISTO',
  Valvea = 'VALVEA',
  Wirmax = 'WIRMAX'
}

export enum ThermalHeat {
  Doors = 'DOORS',
  Floors = 'FLOORS',
  HeatGains = 'HEAT_GAINS',
  HeatPumpGains = 'HEAT_PUMP_GAINS',
  Hotwater = 'HOTWATER',
  PurchasedHeat = 'PURCHASED_HEAT',
  Roof = 'ROOF',
  Ventilation = 'VENTILATION',
  Walls = 'WALLS',
  Windows = 'WINDOWS',
  WindowsAndDoors = 'WINDOWS_AND_DOORS'
}

export type ThermalHeatInput = {
  name: ThermalHeat;
  value: Scalars['Int'];
};

export enum UValue {
  Door = 'DOOR',
  Floor = 'FLOOR',
  Roof = 'ROOF',
  Wall = 'WALL',
  Window = 'WINDOW'
}

export type UValueInput = {
  area: Scalars['Float'];
  current: Scalars['Float'];
  name: UValue;
  potential: Scalars['Float'];
};

export enum Unit {
  ConsumptionGwh = 'CONSUMPTION_GWH',
  ConsumptionKwh = 'CONSUMPTION_KWH',
  ConsumptionKwhPerYear = 'CONSUMPTION_KWH_PER_YEAR',
  ConsumptionMwh = 'CONSUMPTION_MWH',
  ConsumptionMwhPerYear = 'CONSUMPTION_MWH_PER_YEAR',
  EmissionsKgCo2 = 'EMISSIONS_KG_CO2',
  EmissionsKgCo2Millions = 'EMISSIONS_KG_CO2_MILLIONS',
  EmissionsKgCo2PerYear = 'EMISSIONS_KG_CO2_PER_YEAR',
  EmissionsKgCo2Thousands = 'EMISSIONS_KG_CO2_THOUSANDS',
  Eur = 'EUR',
  EurCentsPerKwh = 'EUR_CENTS_PER_KWH',
  EurMillions = 'EUR_MILLIONS',
  EurPerMwh = 'EUR_PER_MWH',
  EurPerSqmPerMonth = 'EUR_PER_SQM_PER_MONTH',
  EurPerYear = 'EUR_PER_YEAR',
  EurThousands = 'EUR_THOUSANDS',
  SquareMeters = 'SQUARE_METERS'
}

/* eslint-disable no-alert */
/* eslint-disable no-console */
import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import {
  BuildingDataModel,
  DefaultEnergyConsumption,
  EnergyConsumptionApiInput,
  NumberOfAcMachines,
  NumberOfAcMachinesInput,
  PdfApiInput,
  PdfSuccess,
  QueryBuildingInput,
} from '../api-types'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
const AXIOS_RETRIES_NUMBER = 3
const AXIOS_RETRY_DELAY_MS = 500

axiosRetry(axios, {
  retries: AXIOS_RETRIES_NUMBER,
  retryDelay: (retryCount) => retryCount * AXIOS_RETRY_DELAY_MS,
})

export const searchPropertiesByAddress = (
  searchParams: QueryBuildingInput
): Promise<BuildingDataModel[]> =>
  axios
    .post(`/query-building-data`, searchParams)
    .then((response) => response.data)
    .catch((e: AxiosError) => {
      console.error(e)
      throw new Error(e.response?.data.detail)
    })

export const createReport = (inputParams: PdfApiInput) =>
  axios
    .post('/create-report', inputParams)
    .then((response) => response.data)
    .catch((e: AxiosError) => {
      console.error(e)
      throw new Error(e.response?.data.detail)
    })

export const calculateEnergyConsumption = (
  searchParams: EnergyConsumptionApiInput
): Promise<DefaultEnergyConsumption> =>
  axios
    .post(`/energy-consumption`, searchParams)
    .then((response) => response.data)
    .catch((e: AxiosError) => {
      console.error(e)
      throw new Error(e.response?.data.detail)
    })

export const updateNumberOfAcMachines = (
  searchParams: NumberOfAcMachinesInput
): Promise<NumberOfAcMachines> =>
  axios
    .post(`/number-of-ac-machines`, searchParams)
    .then((response) => response.data)
    .catch((e: AxiosError) => {
      console.error(e)
      alert(e.response?.data.detail)
      return {}
    })

export const getPdf = (input: PdfApiInput): Promise<PdfSuccess> =>
  axios
    .post(`/pdf`, input)
    .then((response) => response.data)
    .catch((e: AxiosError) => {
      console.error(e)
      throw new Error(e.response?.data.detail)
    })

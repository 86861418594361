import { PropsWithChildren, useState, useEffect, useCallback } from 'react'
import { FormControl, Slider, Typography, Grid, Input, makeStyles } from '@material-ui/core'
import { FieldProps } from 'formik'
import { round } from 'lodash'
import { trackFieldChange } from '../mixpanel'

type Props = FieldProps & {
  min: number
  max: number
  step: number
  label?: string
  percentage?: boolean
  fullWidth?: boolean
  showInputField?: boolean
  disabled?: boolean
  error?: string
}

export default function CustomSliderField(props: PropsWithChildren<Props>) {
  const {
    disabled,
    field,
    form,
    min,
    max,
    showInputField,
    percentage,
    label,
    fullWidth,
    step,
    error,
  } = props
  const classes = useStyles(props)
  const [inputValue, setInputValue] = useState(field.value)
  const [sliderValue, setSliderValue] = useState(field.value)
  useEffect(() => {
    setSliderValue(field.value)
    setInputValue(field.value)
  }, [field.value])

  const setNewValueToField = useCallback(
    (input: string | number | number[]) => {
      trackFieldChange(field.name)
      const newValue = Number(input)
      if (newValue <= min) {
        form.setFieldValue(field.name, min)
      } else if (newValue >= max) {
        form.setFieldValue(field.name, max)
      } else {
        form.setFieldValue(field.name, newValue)
      }
    },
    [field.name, form, max, min]
  )

  return (
    <FormControl disabled={form.isSubmitting || disabled} className={classes.container}>
      <Typography id={field.name} variant="caption" color={error ? 'error' : 'textSecondary'}>
        {label || field.name}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={fullWidth ? 9 : 8}>
          <Slider
            color={error ? 'secondary' : 'primary'}
            disabled={form.isSubmitting || disabled}
            value={sliderValue}
            onChange={(_, newValue) => {
              setSliderValue(newValue)
              setInputValue(String(newValue))
            }}
            aria-labelledby={field.name}
            min={min}
            step={step || (percentage ? 0.005 : 1)}
            max={max || (percentage ? 1 : 5000)}
            onChangeCommitted={(event, newValue) => setNewValueToField(newValue)}
          />
        </Grid>
        <Grid item xs>
          {percentage && <Typography>{formatPercentage(Number(inputValue))}</Typography>}
          {!percentage && (
            <Input
              disabled={form.isSubmitting || disabled}
              readOnly={!showInputField}
              disableUnderline={!showInputField}
              value={roundBasedOnValue(inputValue)}
              onChange={(event) => {
                setInputValue(event.target.value)
                setSliderValue(event.target.value)
              }}
              margin="dense"
              onBlur={(event) => setNewValueToField(event.target.value)}
              inputProps={{
                type: 'tel',
                'aria-labelledby': field.name,
              }}
            />
          )}
        </Grid>
      </Grid>
      {error && (
        <Typography id={`error-${field.name}`} variant="caption" color="error">
          {error}
        </Typography>
      )}
    </FormControl>
  )
}

const useStyles = makeStyles({
  container: {
    width: (props: Props) => (props.fullWidth ? '100%' : '48%'),
  },
})

const formatPercentage = (number: number) =>
  `${Number(Math.round(number * 1000) / 10).toFixed(1)} %`

const roundBasedOnValue = (value: number) => {
  if (value < 5) {
    return round(value, 2)
  }
  if (value < 50) {
    return round(value, 1)
  }
  return round(value)
}

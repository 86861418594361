import { Field, useFormikContext } from 'formik'
import { isUndefined } from 'lodash'
import { useTranslation } from 'react-i18next'
import { PdfApiInput } from '../api-types'
import { CustomTextField } from '../fields'

type Props = {
  disabled: boolean
}
export default function HeatConsumption({ disabled }: Props) {
  const { values } = useFormikContext<PdfApiInput>()
  const { t } = useTranslation()
  const label = values.has_heat_recovery
    ? t('fields.heatingConsumptionWithHeatrecovery')
    : t('fields.heatingConsumption')

  if (isUndefined(values.heat_consumption_mwh)) return null

  return (
    <Field
      name="heat_consumption_mwh"
      label={label}
      component={CustomTextField}
      disabled={disabled}
    />
  )
}

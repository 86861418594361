import { Field } from 'formik'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { includes } from 'lodash'
import { CustomSelectField } from '../fields'
import { BuildingType } from '../api-types'

type Props = {
  disabled: boolean
}

export default function BuildingTypeField({ disabled }: Props) {
  const { t } = useTranslation()
  return (
    <Field
      name="building_type"
      label={t('fields.buildingType')}
      disabled={disabled}
      component={CustomSelectField}
    >
      {buildingTypes.map((buildingType) => (
        <MenuItem key={buildingType} value={buildingType}>
          {t(`buildings.types.${buildingType}`)}
        </MenuItem>
      ))}
    </Field>
  )
}

const residentialBuildingTypes: Partial<BuildingType>[] = ['DETACHED', 'TERRACED', 'APARTMENT']

const buildingTypes: BuildingType[] = [
  ...residentialBuildingTypes,
  'PARKING',
  'OFFICE',
  'INDUSTRIAL',
  'RETAIL',
  'EDUCATION',
  'HEALTH_CARE',
  'LOGISTICS',
  'PUBLIC',
  'OTHER',
]

export const isResidential = (buildingType: BuildingType) => {
  return includes(residentialBuildingTypes, buildingType)
}

import { Field } from 'formik'
import { MenuItem } from '@material-ui/core'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CustomSelectField } from '../fields'

type Props = {
  disabled: boolean
}

export default function HeatingFuel({ disabled }: Props) {
  const { t } = useTranslation()
  return (
    <Field
      name="heating_fuel"
      label={t('fields.heatingFuel')}
      disabled={disabled}
      component={CustomSelectField}
    >
      {map(heatingFuels, (fuel) => (
        <MenuItem key={fuel} value={fuel}>
          {t(`heatingFuel.${fuel}`)}
        </MenuItem>
      ))}
    </Field>
  )
}

const heatingFuels = ['DISTRICT_HEATING', 'ELECTRICITY', 'OIL', 'WOOD', 'GAS'] as const

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { filter, map } from 'lodash'
import { Box, Card, CardActionArea, makeStyles, Typography } from '@material-ui/core'
import { SupportedLanguage } from './i18n'

type Language = {
  key: SupportedLanguage
  label: string
}

const LANGUAGES: Language[] = [
  { key: 'en', label: '🇬🇧' },
  { key: 'fi', label: '🇫🇮' },
]

export default function LanguageSwitch() {
  const { i18n } = useTranslation()
  const { changeLanguage, resolvedLanguage } = i18n

  const languagesToShow = useMemo(
    () => filter(LANGUAGES, ({ key }) => key !== resolvedLanguage),
    [resolvedLanguage]
  )
  const handleLanguageChange = useCallback(
    (selectedLanguage: SupportedLanguage) => changeLanguage(selectedLanguage),
    [changeLanguage]
  )

  return (
    <Box display="flex" justifyContent="center">
      {map(languagesToShow, (language) => (
        <LanguageButton
          label={language.label}
          key={language.key}
          onClick={() => handleLanguageChange(language.key)}
        />
      ))}
    </Box>
  )
}

type ButtonProps = {
  label: string
  onClick: () => void
}
const LanguageButton = ({ label, onClick }: ButtonProps) => {
  const classes = useStyles()
  return (
    <Card elevation={0} className={classes.buttonContainer}>
      <CardActionArea className={classes.button} onClick={onClick}>
        <Typography variant="h6" className={classes.buttonLabel}>
          {label}
        </Typography>
      </CardActionArea>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    backgroundColor: 'transparent',
  },
  button: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonLabel: {
    lineHeight: 1,
  },
}))

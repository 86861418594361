import { omit, values } from 'lodash'
import { Template, ThemeName, Language } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const reaktorFi: Account = {
  name: 'Reaktor fi',
  emailDomain: 'reaktor.fi',
  allowFileDownload: false,
  allowSolar: true,
  authorizeEveryoneFromDomain: true,
  allowedTemplates: [Template.EnergyAnalysis, ...values(omit(Template, 'EnergyAnalysis'))],
  allowedThemes: [ThemeName.Raksystems, ...values(omit(ThemeName, 'Raksystems'))],
  reportLanguages: [Language.Fi],
}

export default reaktorFi

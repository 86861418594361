import { find } from 'lodash'
import { Account } from './models'
import helen from './companies/helen'
import yourspace from './companies/yourspace'
import reaktorCom from './companies/reaktorCom'
import reaktorFi from './companies/reaktorFi'

const accounts: Record<string, Account> = {
  helen,
  yourspace,
  reaktorCom,
  reaktorFi,
}

export const getAccountByEmail = (email: string) => {
  const [, domain] = email.split('@')
  return find(accounts, (account) => domain === account.emailDomain)
}

export function isAuthorizedUser(email: string) {
  const account = getAccountByEmail(email)
  if (!account) return false
  if (account.authorizeEveryoneFromDomain) {
    return true
  }
  if (account.authorizeBySpecificEmail) {
    return !!account.authorizedUsers && account.authorizedUsers.includes(email)
  }
  return false
}

export function isDataFileDownloadAllowed(email: string) {
  const account = getAccountByEmail(email)
  if (!account) return false
  return !!account.allowFileDownload
}

export function isSolarAllowed(email: string) {
  const account = getAccountByEmail(email)
  if (!account) return false
  return !!account.allowSolar
}

export function getAccountDefaultValuesForForm(email: string) {
  const account = getAccountByEmail(email)
  if (!account) {
    const error = new Error(`account not found: ${email}`)
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }

  return {
    defaultLanguage: account.reportLanguages[0],
    defaultTheme: account.allowedThemes[0],
    defaultTemplate: account.allowedTemplates[0],
    showSolar: !!account.allowSolar,
    themes: account.allowedThemes,
    templates: account.allowedTemplates,
    languages: account.reportLanguages,
  }
}

import { Box, BoxProps } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import NumberOfAcMachines from './NumberOfAcMachines'
import { PdfApiInput } from '../api-types'
import { CustomTextField, CustomCheckboxField } from '../fields'

type Props = BoxProps & {
  disabled: boolean
}

export default function HeatRecovery({ disabled, ...props }: Props) {
  const { t } = useTranslation()
  const { values } = useFormikContext<PdfApiInput>()
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props} display="flex" flexGrow={1} flexWrap="wrap">
      <Field
        name="has_mechanical_ventilation"
        component={CustomCheckboxField}
        label={t('fields.hasMechanicalVentilation')}
        disabled={disabled}
      />
      {values.has_mechanical_ventilation && (
        <>
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <NumberOfAcMachines disabled={disabled} />
            {values.exhaust_airflow && (
              <Field
                name="exhaust_airflow"
                label={t('fields.exhaustAirflow')}
                component={CustomTextField}
                disabled={disabled}
              />
            )}
          </Box>
          <Field
            name="has_heat_recovery"
            label={t('fields.hasHeatRecovery')}
            component={CustomCheckboxField}
            disabled={disabled}
          />
        </>
      )}
      {values.has_mechanical_ventilation && values.heat_recovery_capacity && (
        <Field
          name="heat_recovery_capacity"
          label={t('fields.heatRecoveryCapacity')}
          component={CustomTextField}
          disabled
        />
      )}
    </Box>
  )
}

import { useCallback, useState, useEffect } from 'react'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BuildingDataModel, QueryBuildingInput } from '../api-types'
import { searchPropertiesByAddress } from '../lib/api'

const DEBOUNCE_DELAY = 500

type SearchInput = BuildingDataModel['street_address']

const search = async (input: SearchInput) => {
  if (input.length > 0) {
    try {
      const searchParams: QueryBuildingInput = {
        street_address: input,
      }
      return await searchPropertiesByAddress(searchParams)
    } catch (message) {
      // eslint-disable-next-line no-alert
      alert(message)
    }
  }
  return undefined
}

export default function useDebouncedSearch() {
  const { t } = useTranslation()
  const [value, setValue] = useState<SearchInput>('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [properties, setProperties] = useState<BuildingDataModel[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((input: SearchInput) => {
      search(input)
        .then((result) => {
          if (result) {
            setProperties(result)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, DEBOUNCE_DELAY),
    [setProperties, setIsLoading]
  )

  useEffect(() => {
    setIsLoading(value.length > 0)
    setError('')
    debouncedSearch(value)
    if (value.length === 0) {
      setProperties([])
    }
  }, [value, debouncedSearch])

  const notFound =
    !isLoading &&
    !error &&
    properties.length === 0 &&
    value.length > 0 &&
    t('address.notFound', { address: value })

  return {
    value,
    setValue,
    isLoading,
    error: error || notFound,
    properties,
    resetProperties: () => setProperties([]),
  }
}
